
import { defineComponent} from "vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import TableWidget5 from "@/components/widgets/tables/Widget5.vue";
import ListWidget1 from "@/components/widgets/lists/Widget1.vue";
import ListWidget2 from "@/components/widgets/lists/Widget2.vue";
import ListWidget3 from "@/components/widgets/lists/Widget3.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import ListWidget6 from "@/components/widgets/lists/Widget6.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget5.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10-tmp.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import { ApiService } from "@/core/services/ApiService";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import loader from "./utility/loader.vue";

export default defineComponent({
  name: "dashboard",
  components: {
    //TableWidget9,
    // TableWidget5,
    // ListWidget1,
    // ListWidget2,
    // ListWidget3,
    // ListWidget5,
    // ListWidget6,
    // MixedWidget2,
    // MixedWidget5,
    // MixedWidget7,
    //MixedWidget10,
    loader
  },
  data() {
    return {
      showloader: false,
      listMember:{},
      data: {},
      ArrDate: [],
      ArrNbInscrit: [],
      total: 0,
      dateD:"00/00/0000",
      dateF:"00/00/0000",
      membre: {},

      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {},
        tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
      like:0,
      social:[],
      fb:0,
      insta:0,
      mail:0,
      whats:0,
      copy:0,
      inscrit:{},
      url:'',
      pathImg: "",

    };
  },
  methods: {
    async getpathImg() {
      this.pathImg = await process.env.VUE_APP_FILE_IMG;
    },
    getListeMember() {
      ApiService.setHeader();
      ApiService.get("membre/ListeMembre")
        .then(async (result) => {
          this.listMember = await result.data;
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getstatInscription() {
      this.showloader = true;
      ApiService.setHeader();
      ApiService.get("formation/offre/statInscrit")
        .then(async (result) => {
          this.data = await result.data;
          await this.traitement_data_graph_inscription(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async traitement_data_graph_inscription(data) {
      let ad: any = [];
      let ni: any = [];
      let tot: any = 0;
      await data.forEach(async (item) => {
        ad.push(item.daty);
        ni.push(item.nombre);
        tot = tot + parseInt(item.nombre);
      });
      this.ArrDate = await ad;
      this.ArrNbInscrit = await ni;
      this.total = tot;

      if(ad){
        this.dateD = ad[0];
        const n = ad.length;
        this.dateF = ad[n-1]; 
      }
      

      this.chartOptions = await {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: ad,
          },
        },
      };

      this.series = await [
        {
          name: "Nb inscrit",
          data: ni,
        },
      ];
    },
    getNumberLike() {
      ApiService.setHeader();
      ApiService.get("/like")
        .then(async (result) => {
          this.like = await result.data;
          this.showloader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getNumberSharesocial() {
      ApiService.setHeader();
      ApiService.get("/sharesocial")
        .then(async (reponse) => {
          this.social = await reponse.data;
          this.fb = this.social['countfacebook'][0];
          this.insta = this.social['countinstag'][0];
          this.mail = this.social['countmail'][0];
          this.whats = this.social['countwhats'][0];
          this.copy = this.social['countcopy'][0];
          this.showloader = false;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListeNewsletter() {
      ApiService.setHeader();
      ApiService.get("/inscriptionnews")
        .then(async (reponse) => {        
          this.inscrit = await reponse.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getExports() {
      this.url = await process.env.VUE_APP_API_URL;
    },
    AfficheMembre(id) {
      ApiService.setHeader();
      ApiService.get("/membre/One/"+id)
        .then((resp) => {
          console.log(resp);
           this.membre = resp.data.user
        })
        .catch((error) => {
          console.log(error);
        });
    },

  },
  mounted() {
     this.getstatInscription();
     this.getListeMember();
     this.getNumberLike();
     this.getNumberSharesocial();
     this.getListeNewsletter();
     this.getExports();
     this.getpathImg();
  },
});
